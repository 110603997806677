.logo {
    position: fixed;
    top: 0.75rem; 
    left: 1.5rem;
    font-style: italic;
    letter-spacing: 12px;
    color: rgb(255, 255, 255, 0.75);
    background: none;
    animation: hoverAnimation 4s ease-in-out infinite, fadeAnimation 8s ease-in-out infinite;
    user-select: none;
  }
  
  @keyframes hoverAnimation {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  
  @keyframes fadeAnimation {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  