.wrapper {
    min-width: 100%;
    margin: 24px;
    padding: 1rem;
    border: 2px solid white;
    border-radius: 8px; 
    z-index: 1;
}

.noselect {
    user-select: none;
}

.actionWrapper {
    margin: 8px;
    padding: 0.35rem;
    display: flex;
    justify-content: center;
    background: white;
    color: black; 
    border-radius: 16px;
}

.actionWrapper:hover {
    background: #E20074;
    color: white; 
}

.infoMessage {
    margin: 36px;
    z-index: 1;
}

.actionBox {
    margin: 4px;
}

.btn {
    width: 42px; 
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px; 
    margin-left: auto;
    margin-right: auto;
    padding: 2px 4px;
    background: white;
    color: black;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    z-index: 1;
}

.btn.clear {
    background: transparent;
    border: none;
    color: white;
}

.btn:hover {
    background: #E20074;
    color: white;
    border: 2px solid white;
    font-weight: 600;
}

.behaviorEditor {
    width: max-content;
    height: max-content;
}

.callTypeSelector {
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;
}

.icon {
    width: 3rem; 
    height: 3rem;
    padding: 0rem 0.25rem 0rem 0.25rem;
}

