/* import fonts  */
@import url('https://fonts.googleapis.com/css?family=Kaushan+Script');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+Symbols+2');

/* Root Colors */
:root {
  --color-notice: hsl(235deg 100% 50%);
  --color-notice-bg: hsl(235deg 0% 100%);
  --color-warning: hsl(35deg 100% 46%);
  --color-warning-bg: hsl(40deg 100% 94%);
  --color-success: hsl(120deg 80% 35%);
  --color-success-bg: hsl(120deg 90% 96%);
  --color-error: hsl(345deg 100% 50%);
  --color-error-bg: hsl(350deg 90% 96%);
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.noselect {
    user-select: none;
}

.fireworks {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10% 10% 0% 10%;
  position: fixed;
  background: transparent;
  z-index: 0;

}

.resetButton {
  margin: 2rem 6px;
  padding: 0.4rem 0.6rem;
  border: 0;
  border-radius: 8px;
  justify-self: self-end;
  z-index: 1;
}

.resetButton:hover {
  color: white;
  background: #E20074;
}

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}