.backdrop {
  position: absolute;
  inset: 0;
  transition: opacity 2s ease-in-out;
  opacity: 0.65;
}

.backdrop.open {
  z-index: 10;
  background: hsl(222deg 20% 10% / 0.75);
  backdrop-filter: blur(4px);
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.allTabs {
  width: 0;
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  z-index: 999;

}

.panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 40%;
  max-width: 400px;
  background-color: #f1f1f1;
  transition: transform 0.2s ease-in-out;
  transform: translateX(-100%);
  z-index: 1000;
}
  
.panel.open {
  transform: translateX(0);
}
  
.tab {
  opacity: 1; 
  position: relative;
  width: 2.5rem;
  height: 3.5rem;
  background-color: #ccc;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 0% 50% 50% 0%;
  font-size: 2.5rem; 
  user-select: none;
  text-align: center;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear;
}

.tab:hover {
  background: #E20074;
  color: white; 
}

.tab.hidden {
  transform: translateX(-100%);
  opacity: 0; 
  pointer-events: none; 
}

.tabContent {
  width: 1.75rem;
  height: 1.75rem;
  position: relative;
  left: -35%;
  top: -15%;
}
  
.content {
  padding: 20px;
  z-index: 1000; 
}

.tooSmall {
    display: none;
    padding: 0.25rem;
}

@media (max-width: 600px) {
    .tooSmall {
        display: block;
        position: absolute;
        top: 50%;
        left: 0%;
    }

    .content {
        display: none;
    }
}