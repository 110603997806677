.toast {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  color: black;
  color-scheme: light;
  background: white;
  max-width: 100%;
  width: 350px;
  box-shadow: var(--shadow-elevation-medium);
}

.toastContent {
  flex: 1;
  padding: 12px 0px;
  font-weight: 600;
}

.toastIconContainer {
  flex-shrink: 0;
  padding: 16px;
  padding-right: 0px;
}
.iconContainer svg {
  display: block;
}

.title {
  font-weight: 700;
  margin-bottom: 4px;
}

.toastCloseButton {
  flex-shrink: 0;
  border: none;
  background: transparent;
  padding: 16px;
  cursor: pointer;
}

.notice {
  background: var(--color-notice-bg);
}
.notice .toastIconContainer {
  color: var(--color-notice);
}
.warning {
  background: var(--color-warning-bg);
}
.warning .toastIconContainer {
  color: var(--color-warning);
}
.success {
  background: var(--color-success-bg);
}
.success .toastIconContainer {
  color: var(--color-success);
}
.error {
  background: var(--color-error-bg);
}
.error .toastIconContainer {
  color: var(--color-error);
}
